import React from 'react';
import InfoTooltip from '../../components/custom-tooltip/InfoTooltip';
const BreakdownContent = ({ rate, interest, legalDocumentVersion }) => {
    return (React.createElement("div", { className: 'tooltip-content', "data-testid": 'tooltip' },
        React.createElement("div", { className: 'title' }, "Breakdown"),
        React.createElement("div", { className: 'body' },
            "Base Rate (",
            React.createElement("span", null, rate),
            ") +",
            ' ',
            legalDocumentVersion === 'v2024Oct'
                ? 'Margin'
                : 'Interest Rate Margin',
            ' ',
            "(",
            React.createElement("span", null, interest),
            ")")));
};
const BreakdownInterest = ({ tooltipId, tooltipDirection, baseRate, interestMargin, legalDocumentVersion }) => {
    const tooltipProps = {
        tooltipId: tooltipId,
        tooltipDirection: tooltipDirection,
        customStyle: 'tooltip-content'
    };
    return (React.createElement(InfoTooltip, { ...tooltipProps },
        React.createElement(BreakdownContent, { rate: baseRate, interest: interestMargin, legalDocumentVersion: legalDocumentVersion })));
};
export default BreakdownInterest;
