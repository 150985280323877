import React, { useState, useEffect } from 'react';
import { getNavigationData } from '../../api/client_setup';
import { isAPIErrors } from '../../../types';
import VerticalNavigation from '../../components/vertical-navigation/VerticalNavigation';
import ProcessingBadge from '../../components/manual-ledger-funding/ProcessingBadge';
import EarlypayRegionOwnerForm from '../../components/client-setup/EarlypayRegionOwnerForm';
import ProductDetailsForm from '../../components/client-setup/ProductDetailsForm';
import IndustriesForm from '../../components/client-setup/IndustriesForm';
import TradingTermsForm from '../../components/client-setup/TradingTermsForm';
import InsuranceStatusForm from '../../components/client-setup/InsuranceStatusForm';
import DebtorConcentrationsForm from '../../components/client-setup/DebtorConcentrationsForm';
import ExcessReceiptProcedureForm from '../../components/client-setup/ExcessReceiptProcedureForm';
import DebtorStatusForm from '../../components/client-setup/DebtorStatusForm';
import DebtorAgeingThresholdForm from '../../components/client-setup/DebtorAgeingThresholdForm';
import RecourseTermForm from '../../components/client-setup/RecourseTermForm';
import AmendedInvoiceThresholdForm from '../../components/client-setup/AmendedInvoiceThresholdForm';
const GeneralClientSetupContainer = ({ authToken, borrowerId, companyId, client, industry, platformDefaultLimitCents, platformDefaultLimitRate, navigationItems, productTypeOptions, fundedLedgerOptions, servicePoolOptions, stateManagerOptions, industryNameOptions, tradingTermsOptions, debtorInsuranceStatusOptions, excessReceiptProcedureOptions, discloseTypeOptions, debtorStatus, accessControls, amendedInvoiceThreshold, recourseTerm, enableAmendedInvoice, isRecourseTermProcessingStatus }) => {
    const { earlypayRegionOwnerReadOnly, productDetailsReadOnly, industriesReadOnly, tradingTermsReadOnly, insuranceStatusReadOnly, debtorConcentrationsReadOnly, excessReceiptReadOnly, debtorStatusReadOnly, debtorAgeingThresholdReadOnly, AmendedInvoiceThresholdFormReadOnly, recourseTermReadOnly } = accessControls;
    const [currentNavItems, setCurrentNavItems] = useState(navigationItems);
    const [numberOfSaves, setNumberOfSaves] = useState(0);
    const [isRecourseTermProcessing, setIsRecourseTermProcessing] = useState(isRecourseTermProcessingStatus);
    useEffect(() => {
        if (numberOfSaves > 0) {
            loadNavItems();
        }
    }, [numberOfSaves]);
    const loadNavItems = async () => {
        try {
            const response = await getNavigationData(authToken, borrowerId);
            if (response.data) {
                setCurrentNavItems(response.data);
            }
        }
        catch (err) {
            if (isAPIErrors(err)) {
                throw new Error(err.errors.map(error => error.title).join(','));
            }
        }
    };
    const incrementSaves = () => {
        setNumberOfSaves(previous => previous + 1);
    };
    return (React.createElement("div", { className: 'borrower-settings-container -general-client-setup' },
        React.createElement("div", { className: 'sidebar' },
            React.createElement(VerticalNavigation, { items: currentNavItems, isScrollable: true })),
        React.createElement("div", { className: 'content' },
            React.createElement("div", { id: 'earlypay-region-owner', className: 'custom-panel panel' },
                React.createElement("div", { className: 'header' }, "Earlypay Region Owner"),
                React.createElement("div", { className: 'body' },
                    React.createElement(EarlypayRegionOwnerForm, { ...{
                            authToken,
                            borrowerId,
                            client,
                            stateManagerOptions,
                            incrementSaves
                        }, isReadOnly: earlypayRegionOwnerReadOnly }))),
            React.createElement("div", { id: 'product-details', className: 'custom-panel panel' },
                React.createElement("div", { className: 'header' }, "Product Details"),
                React.createElement("div", { className: 'body' },
                    React.createElement(ProductDetailsForm, { ...{
                            authToken,
                            borrowerId,
                            productTypeOptions,
                            fundedLedgerOptions,
                            servicePoolOptions,
                            discloseTypeOptions,
                            client,
                            incrementSaves
                        }, isReadOnly: productDetailsReadOnly }))),
            React.createElement("div", { id: 'industries', className: 'custom-panel panel' },
                React.createElement("div", { className: 'header' }, "Industries"),
                React.createElement("div", { className: 'body' },
                    React.createElement(IndustriesForm, { ...{
                            authToken,
                            borrowerId,
                            client,
                            industry,
                            industryNameOptions,
                            incrementSaves
                        }, isReadOnly: industriesReadOnly }))),
            React.createElement("div", { id: 'excess-receipt-procedure', className: 'custom-panel panel' },
                React.createElement("div", { className: 'header' }, "Excess Receipt Procedure"),
                React.createElement("div", { className: 'body' },
                    React.createElement(ExcessReceiptProcedureForm, { ...{
                            authToken,
                            borrowerId,
                            excessReceiptProcedureOptions,
                            client,
                            incrementSaves
                        }, isReadOnly: excessReceiptReadOnly }))),
            React.createElement("div", { id: 'trading-terms', className: 'custom-panel panel' },
                React.createElement("div", { className: 'header' }, "Select a Standard Trading Term"),
                React.createElement("div", { className: 'body' },
                    React.createElement(TradingTermsForm, { ...{
                            authToken,
                            borrowerId,
                            tradingTermsOptions,
                            client,
                            incrementSaves
                        }, isReadOnly: tradingTermsReadOnly }))),
            React.createElement("div", { id: 'insurance-status', className: 'custom-panel panel' },
                React.createElement("div", { className: 'header' }, "Insurance Status"),
                React.createElement("div", { className: 'body' },
                    React.createElement(InsuranceStatusForm, { ...{
                            authToken,
                            borrowerId,
                            debtorInsuranceStatusOptions,
                            client,
                            incrementSaves
                        }, isReadOnly: insuranceStatusReadOnly }))),
            React.createElement("div", { id: 'debtor-concentrations', className: 'custom-panel panel' },
                React.createElement("div", { className: 'header' }, "Debtor Concentrations"),
                React.createElement("div", { className: 'body' },
                    React.createElement(DebtorConcentrationsForm, { ...{
                            authToken,
                            borrowerId,
                            client,
                            platformDefaultLimitCents,
                            platformDefaultLimitRate,
                            incrementSaves
                        }, isReadOnly: debtorConcentrationsReadOnly }))),
            React.createElement("div", { id: 'debtor-ageing-threshold', className: 'custom-panel panel' },
                React.createElement("div", { className: 'header' }, "Debtor Ageing Threshold Exceeded"),
                React.createElement("div", { className: 'body' },
                    React.createElement(DebtorAgeingThresholdForm, { ...{
                            authToken,
                            borrowerId,
                            client,
                            incrementSaves
                        }, isReadOnly: debtorAgeingThresholdReadOnly }))),
            React.createElement("div", { id: 'amended-invoice-threshold', className: 'custom-panel panel' },
                React.createElement("div", { className: 'header' }, "Amended Invoices Threshold"),
                React.createElement("div", { className: 'body' },
                    React.createElement(AmendedInvoiceThresholdForm, { ...{
                            authToken,
                            borrowerId,
                            amendedInvoiceThreshold,
                            enableAmendedInvoice,
                            incrementSaves
                        }, isReadOnly: AmendedInvoiceThresholdFormReadOnly }))),
            React.createElement("div", { id: 'recourse-term', className: 'custom-panel panel' },
                React.createElement("div", { className: 'header' },
                    React.createElement("div", null, "Recourse Term"),
                    isRecourseTermProcessing && React.createElement(ProcessingBadge, null)),
                React.createElement("div", { className: 'body' },
                    React.createElement(RecourseTermForm, { ...{
                            authToken,
                            companyId,
                            recourseTerm,
                            incrementSaves,
                            isRecourseTermProcessing,
                            setIsRecourseTermProcessing
                        }, isReadOnly: recourseTermReadOnly }))),
            React.createElement("div", { id: 'debtor-status', className: 'custom-panel panel' },
                React.createElement("div", { className: 'header' }, "Default Debtor Status"),
                React.createElement("div", { className: 'body' },
                    React.createElement(DebtorStatusForm, { ...{
                            authToken,
                            borrowerId,
                            client,
                            debtorStatus,
                            incrementSaves
                        }, isReadOnly: debtorStatusReadOnly }))))));
};
export default GeneralClientSetupContainer;
