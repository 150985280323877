import React, { useState } from 'react';
import Select from 'react-select';
import ErrorList from '../shared/ErrorList';
import SpinnerButton from '../shared/SpinnerButton';
import { updateProductDetails } from '../../api/client_setup';
import { isAPIValidationErrors, isAPIErrors } from '../../../types';
import { dropdownStyles } from '../../custom-styles/dropdown';
import { findOptionByValue, showNotifyToast } from '../../utils';
const ProductDetailsForm = ({ authToken, borrowerId, client, productTypeOptions, fundedLedgerOptions, servicePoolOptions, discloseTypeOptions, isReadOnly = false, incrementSaves }) => {
    const { productType, discloseType, servicePool, fundedLedger } = client;
    const initialProductType = findOptionByValue(productTypeOptions, productType);
    const initialFundedLedger = findOptionByValue(fundedLedgerOptions, fundedLedger);
    const initialServicePool = findOptionByValue(servicePoolOptions, servicePool);
    const initialDiscloseType = findOptionByValue(discloseTypeOptions, discloseType);
    const [selectedProductType, setSelectedProductType] = useState(initialProductType);
    const [selectedFundedLedger, setSelectedFundedLedger] = useState(initialFundedLedger);
    const [selectedServicePool, setSelectedServicePool] = useState(initialServicePool);
    const [selectedDiscloseType, setSelectedDiscloseType] = useState(initialDiscloseType);
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const handleProductTypeChange = (selectedOption) => {
        setSelectedProductType(selectedOption);
    };
    const handleFundedLedgerChange = (selectedOption) => {
        setSelectedFundedLedger(selectedOption);
    };
    const handleServicePoolChange = (selectedOption) => {
        setSelectedServicePool(selectedOption);
    };
    const handleDiscloseTypeChange = (selectedOption) => {
        setSelectedDiscloseType(selectedOption);
    };
    const handleSave = async () => {
        const body = {
            company: {
                productType: selectedProductType ? selectedProductType.value : '',
                servicePool: selectedServicePool ? selectedServicePool.value : null,
                fundedLedger: selectedFundedLedger ? selectedFundedLedger.value : null,
                discloseType: selectedDiscloseType ? selectedDiscloseType.value : ''
            }
        };
        setIsLoading(true);
        try {
            const response = await updateProductDetails(authToken, borrowerId, body);
            if (isAPIValidationErrors(response)) {
                throw response;
            }
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            setValidationErrors({});
            if (incrementSaves) {
                incrementSaves();
            }
            showNotifyToast({
                text: 'Successfully updated Product Details.',
                type: 'success'
            });
        }
        catch (err) {
            if (isAPIValidationErrors(err)) {
                setValidationErrors(err.errors);
            }
            showNotifyToast({
                text: 'Failed to update Product Details.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    return (React.createElement("div", { className: 'client-setup-form form -product-details', "data-testid": 'product-details-form' },
        React.createElement("div", { className: 'form-group group' },
            React.createElement("label", { className: 'label -uppercase' }, "Confidentiality"),
            React.createElement(Select, { options: discloseTypeOptions, styles: dropdownStyles, placeholder: 'Select options', value: selectedDiscloseType, onChange: handleDiscloseTypeChange, isDisabled: isReadOnly }),
            React.createElement("div", { className: 'text -spacer' }, "Select whether this client is disclosed or non-disclosed. Disclosed clients will receive communication with Earlypay branding whilst non-disclosed clients won\u2019t.")),
        React.createElement("div", { className: 'form-group group -divider' },
            React.createElement("label", { className: 'label -uppercase' }, "Product Type"),
            React.createElement(Select, { options: productTypeOptions, styles: dropdownStyles, placeholder: 'Select product type', value: selectedProductType, onChange: handleProductTypeChange, isDisabled: isReadOnly }),
            React.createElement(ErrorList, { errors: validationErrors, field: 'product_type' })),
        React.createElement("div", { className: 'form-group group' },
            React.createElement("label", { className: 'label -uppercase' }, "Funded Ledger"),
            React.createElement(Select, { options: fundedLedgerOptions, styles: dropdownStyles, placeholder: 'Select funded ledger', value: selectedFundedLedger, onChange: handleFundedLedgerChange, isDisabled: isReadOnly, displayEmpty: true }),
            React.createElement(ErrorList, { errors: validationErrors, field: 'funded_ledger' })),
        React.createElement("div", { className: 'form-group group' },
            React.createElement("label", { className: 'label -uppercase' }, "Service Pool"),
            React.createElement(Select, { options: servicePoolOptions, styles: dropdownStyles, placeholder: 'Select service pool', value: selectedServicePool, onChange: handleServicePoolChange, isDisabled: isReadOnly }),
            React.createElement(ErrorList, { errors: validationErrors, field: 'service_pool' })),
        React.createElement("div", { className: 'action-buttons actions' },
            React.createElement(SpinnerButton, { text: 'Save', buttonType: 'button', isLoading: isLoading, handleClick: handleSave, className: 'button -primary', isDisabled: isReadOnly, testId: 'save' }))));
};
export default ProductDetailsForm;
