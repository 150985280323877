import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { convertToMoneyObject } from '../../utils/money';
import { computeGst, computeAmountWithGst, computeAmount } from '../../utils/oneOffFees';
import GstField from './GstField';
const OneOffFeesEditor = ({ formAttributes, feeTypeOptions, feeTypesWithoutGst, readOnly = false }) => {
    const { url, csrfToken } = formAttributes;
    const [amount, setAmount] = useState(0);
    const [amountWithGst, setAmountWithGst] = useState(0);
    const [gstValue, setGstValue] = useState(0);
    const [feeTypeOption, setFeeTypeOption] = useState();
    const selectedFeeType = (feeTypeOption === null || feeTypeOption === void 0 ? void 0 : feeTypeOption.value[0]) || '';
    useEffect(() => {
        setAmountWithGst(computeAmountWithGst(amount));
        setGstValue(computeGst(amount));
    }, [amount]);
    useEffect(() => {
        const updatedAmount = computeAmount(amountWithGst);
        setAmount(updatedAmount);
        setGstValue(computeGst(updatedAmount));
    }, [amountWithGst]);
    const submitButtonClass = classNames('custom-button update -default -narrow', {
        '-disabled': readOnly
    });
    const updateAmountWithGst = (newAmount) => {
        if (newAmount) {
            const updatedAmount = convertToMoneyObject(newAmount);
            setAmountWithGst(updatedAmount.toUnit());
        }
        else {
            setAmountWithGst(0);
        }
    };
    const handleAmountChange = ({ floatValue }) => {
        if (floatValue) {
            const amountCentsObject = convertToMoneyObject(floatValue);
            setAmount(amountCentsObject.toUnit());
        }
        else {
            setAmount(0);
        }
    };
    return (React.createElement("form", { className: 'form form-pane', action: url, acceptCharset: 'UTF-8', method: 'post', "data-testid": 'one-off-fees-attributes-editor' },
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("div", { className: 'form-group' },
            React.createElement("div", { className: 'label' }, "Fee type"),
            React.createElement(Select, { name: 'one_off_fee[fee_type]', classNames: 'input', options: feeTypeOptions, value: feeTypeOption, onChange: setFeeTypeOption, isDisabled: readOnly })),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label' },
                "Fee Description",
                React.createElement("input", { type: 'text', className: 'input form-control', name: 'one_off_fee[name]', id: 'one_off_fee_name', disabled: readOnly }))),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label' },
                feeTypesWithoutGst.includes(selectedFeeType)
                    ? 'Fee Amount ($)'
                    : 'Fee Amount exc GST ($)',
                React.createElement(NumberFormat, { value: amount, className: 'input form-control', name: 'one_off_fee[amount]', onValueChange: handleAmountChange, disabled: readOnly, decimalScale: 2, thousandSeparator: true }))),
        React.createElement(GstField, { model: 'one_off_fee', gstAttribute: 'gst', gstValue: gstValue, amount: amount, amountWithGst: amountWithGst, amountAttribute: 'amount_with_gst', updateAmountWithGst: updateAmountWithGst, hidden: feeTypesWithoutGst.includes(selectedFeeType) }),
        React.createElement("input", { type: 'submit', className: submitButtonClass, name: 'commit', value: 'Create', "data-disable-with": 'Creating...', disabled: readOnly })));
};
export default OneOffFeesEditor;
