import React, { useState, useRef } from 'react';
import ToggleInput from './ToggleInput';
import NumberField from '../shared/NumberField';
import NumberFormGroupWithGst from '../shared/NumberFormGroupWithGst';
import classNames from 'classnames';
import CustomFlatpickr from '../shared/CustomFlatpickr';
const FundingAttributesEditor = ({ formAttributes, company, readOnly = false }) => {
    const { connectedAccountingPlatform } = company;
    const [eligibleForTransferBack, setEligibleForTransferBack] = useState(company.eligibleForTransferBack);
    const [enableXeroFee, setEnableXeroFee] = useState(company.enableXeroPlatformFee);
    const [enableCheckBankTransaction, setEnableCheckBankTransaction] = useState(company.enableBankTransactionCheck);
    const [selectedDate, setSelectedDate] = useState(company.annualFeeAnniversaryDate || '');
    const { url, csrfToken } = formAttributes;
    const handleSelectedDateChange = (selectedDates, dateString) => {
        setSelectedDate(dateString);
    };
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d',
        defaultDate: selectedDate,
        onChange: handleSelectedDateChange
    };
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const switchOnTransferBack = () => {
        setEligibleForTransferBack(true);
    };
    const switchOffTransferBack = () => {
        setEligibleForTransferBack(false);
    };
    const switchOnXeroFee = () => {
        setEnableXeroFee(true);
    };
    const switchOffXeroFee = () => {
        setEnableXeroFee(false);
    };
    const switchOnCheckBankTransaction = () => {
        setEnableCheckBankTransaction(true);
    };
    const switchOffCheckBankTransaction = () => {
        setEnableCheckBankTransaction(false);
    };
    const submitClassNames = classNames('custom-button update -default -narrow', {
        '-disabled': readOnly
    });
    return (React.createElement("div", { className: 'funding-attributes-editor editor', "data-testid": 'funding-attributes-editor' },
        React.createElement("form", { acceptCharset: 'UTF-8', method: 'post', action: url, className: 'form' },
            React.createElement("input", { type: 'hidden', name: '_method', value: 'put' }),
            React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Credit Limit ($)"),
                React.createElement(NumberField, { model: 'company', attribute: 'credit_limit', defaultValue: company.creditLimitFormatted, required: true })),
            React.createElement(NumberFormGroupWithGst, { attribute: 'annual_fee', defaultValue: company.annualFeeFormatted, labelText: 'Annual Fee ($)', model: 'company', gstDefaultChecked: company.annualFeeGst, readOnly: readOnly, required: true }),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Annual Fee Anniversary Date"),
                React.createElement(CustomFlatpickr, { ...{ selectedDate, flatpickrSettings }, forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, clearButtonText: 'Clear Date', name: `company[annual_fee_anniversary_date]`, pickerBackground: 'neutral-20', placeholderText: ' ' })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Minimum Drawdown Fee ($)"),
                React.createElement(NumberField, { model: 'company', attribute: 'minimum_drawdown_fee', defaultValue: company.minimumDrawdownFeeFormatted, required: true })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, company.legalDocumentVersion === 'v2024Oct'
                    ? 'Minimum Monthly Advance Fee ($)'
                    : 'Minimum Monthly Drawdown Fee ($)'),
                React.createElement(NumberField, { model: 'company', attribute: 'minimum_monthly_drawdown_fee', defaultValue: company.minimumMonthlyDrawdownFeeFormatted, required: true })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Drawdown Fee (%)"),
                React.createElement(NumberField, { attribute: 'drawdown_rate', defaultValue: company.drawdownRateFormatted, model: 'company', readOnly: readOnly, allowNegative: true, required: true })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, company.legalDocumentVersion === 'v2024Oct'
                    ? 'Margin (%)'
                    : 'Interest Rate Margin (%)'),
                React.createElement(NumberField, { attribute: 'interest_rate_margin', defaultValue: company.interestRateMarginFormatted, model: 'company', readOnly: readOnly, allowNegative: true, required: true })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Advance Rate (%)"),
                React.createElement(NumberField, { attribute: 'advance_rate', defaultValue: company.advanceRateFormatted, model: 'company', readOnly: readOnly, allowNegative: true, required: true })),
            React.createElement(NumberFormGroupWithGst, { attribute: 'admin_fee_rate', defaultValue: company.adminFeeRateFormatted, labelText: 'Admin Fee Rate (%)', model: 'company', gstDefaultChecked: company.adminFeeRateGst, gstDecimalScale: 3, readOnly: readOnly, allowNegative: true, required: true }),
            React.createElement(NumberFormGroupWithGst, { attribute: 'insurance_fee_rate', defaultValue: company.insuranceFeeRateFormatted, labelText: 'Insurance Fee Rate (%)', model: 'company', gstDefaultChecked: company.insuranceFeeRateGst, gstDecimalScale: 3, readOnly: readOnly, allowNegative: true, required: true }),
            React.createElement(NumberFormGroupWithGst, { attribute: 'aged_receivables_fee_rate', defaultValue: company.agedReceivablesFeeRateFormatted, labelText: 'Recourse Fee Rate (%)', model: 'company', gstDefaultChecked: company.agedReceivablesFeeRateGst, gstDecimalScale: 3, readOnly: readOnly, allowNegative: true, required: true }),
            React.createElement(NumberFormGroupWithGst, { attribute: 'credit_note_fee_rate', defaultValue: company.creditNoteFeeRateFormatted, labelText: 'Credit Note Fee Rate (%)', model: 'company', gstDefaultChecked: company.creditNoteFeeRateGst, gstDecimalScale: 3, readOnly: readOnly, allowNegative: true, required: true }),
            React.createElement(NumberFormGroupWithGst, { attribute: 'minimum_admin_fee', defaultValue: company.minimumAdminFeeFormatted, labelText: company.legalDocumentVersion === 'v2024Oct'
                    ? 'Minimum Monthly Administration Fee ($)'
                    : 'Minimum Monthly Admin Fee ($)', model: 'company', gstDefaultChecked: company.minimumAdminFeeGst, readOnly: readOnly, required: true }),
            React.createElement(NumberFormGroupWithGst, { attribute: 'minimum_interest_fee', defaultValue: company.minimumInterestFeeFormatted, labelText: 'Minimum Monthly Interest Fee ($)', model: 'company', gstDefaultChecked: company.minimumInterestFeeGst, readOnly: readOnly, required: true }),
            connectedAccountingPlatform === 'xero' && (React.createElement(ToggleInput, { attribute: 'enable_bank_transaction_check', isChecked: enableCheckBankTransaction, labelText: 'Check bank transactions', switchOn: switchOnCheckBankTransaction, switchOff: switchOffCheckBankTransaction, model: 'company', readOnly: readOnly })),
            connectedAccountingPlatform === 'xero' && (React.createElement(ToggleInput, { attribute: 'enable_xero_platform_fee', isChecked: enableXeroFee, labelText: 'Charge monthly Xero platform fee', switchOn: switchOnXeroFee, switchOff: switchOffXeroFee, model: 'company', readOnly: readOnly })),
            React.createElement(ToggleInput, { attribute: 'eligible_for_transfer_back', isChecked: eligibleForTransferBack, labelText: 'Eligible for Transfer Backs?', model: 'company', switchOn: switchOnTransferBack, switchOff: switchOffTransferBack, readOnly: readOnly }),
            eligibleForTransferBack && (React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Transfer Back (%)"),
                React.createElement(NumberField, { attribute: 'transfer_back_rate', defaultValue: company.transferBackRateFormatted, model: 'company', readOnly: readOnly, required: true }))),
            React.createElement("input", { type: 'submit', className: submitClassNames, name: 'commit', value: 'Update', "data-disable-with": 'Updating...', disabled: readOnly }))));
};
export default FundingAttributesEditor;
